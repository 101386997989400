import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inputState } from "../states/atoms/inputAtom";

import { STORAGE_KEY, JAPANESE_DATE_FORMAT } from "../Constants";
import { getSessionStorageItem } from "../utils/StorageUtil";
import moment from "moment";

const RegisterComplete = () => {
  const [input] = useRecoilState(inputState);

  const [searchParams] = useSearchParams();

  const location = useLocation();

  const resultState = location.state;

  const shopName = getSessionStorageItem(STORAGE_KEY.SHOP_NAME);

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  });

  return (
    <main className="main">
      {/* cover */}
      <div className="cover cover--contact cover--bg">
        <div className="cover__outer">
          <div className="cover__inner"></div>
        </div>
      </div>
      {/* /cover */}

      {/* location */}
      <div className="location">
        <p className="location__text">
          <a href={process.env.REACT_APP_WEB_MAIN_URL}>TOP</a>
          <a href={process.env.REACT_APP_WEB_MAIN_URL + "/admission/"}>
            ワールドプラスジム　{!!shopName && shopName + "　"}
            入会のお申し込み
          </a>
        </p>
      </div>
      {/* /location */}

      {/* content */}
      <div className="content">
        <div className="content__outer">
          <div className="content__inner">
            <h1 className="content__title js-indicate js-indicate-btin">
              ワールドプラスジム　{!!shopName && shopName + "　"}
              入会のお申し込み
            </h1>

            {/* step */}
            <div className="step js-indicate js-indicate-btin">
              <div className="step__lists">
                <div className="step__list">
                  <span>
                    プラン<i className="pc">等の選択</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    会員<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    決済<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list step__list--current">
                  <span>
                    登録<i className="pc">完了</i>
                  </span>
                </div>
              </div>
            </div>
            {/* /step */}

            {/* message */}
            <div className="message js-indicate js-indicate-btin">
              <div className="message__box">
                <p className="message__title message__title--large">
                  決済登録完了
                </p>
                {/* if @individual_member && @is_show_lead_data */}
                <div className="message__details">
                  <table>
                    <tbody>
                      <tr>
                        <th>入会用会員ID</th>
                        <td>
                          {searchParams.get("memberId") || resultState.memberId}
                        </td>
                      </tr>
                      <tr>
                        <th>お名前</th>
                        <td>
                          {/*= @individual_member.family_name + @individual_member.first_name */}
                          {searchParams.get("name") || resultState.name}
                        </td>
                      </tr>
                      <tr>
                        <th>メールアドレス</th>
                        <td>
                          {searchParams.get("email") || resultState.email}
                        </td>
                      </tr>
                      <tr>
                        <th>ご来店予定店舗</th>
                        <td>
                          {searchParams.get("shopName") || resultState.shopName}
                          店
                        </td>
                      </tr>
                      <tr>
                        <th>入会日</th>
                        <td>
                          {moment(
                            searchParams.get("joinDate") || resultState.joinDate
                          ).format(JAPANESE_DATE_FORMAT)}
                        </td>
                      </tr>
                      {(searchParams.get("referralPlan") || resultState?.referralPlan) && (
                        <tr>
                          <th>紹介特典</th>
                          <td>
                            {searchParams.get("referralPlan") || resultState?.referralPlan}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>プラン</th>
                        <td>
                          {searchParams.get("planName") || resultState.planName}
                        </td>
                      </tr>
                      <tr>
                        <th valign="top">オプション</th>
                        <td>
                          {(searchParams.get("options") || resultState?.options)
                            ?.split(/\n/)
                            .map((str, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {str}
                                  <br />
                                </React.Fragment>
                              );
                            })}
                        </td>
                      </tr>
                      <tr>
                        <th>決済種別</th>
                        <td>
                          {searchParams.get("paymentMethod") ||
                            resultState.paymentMethod}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {input.paymentMethod === "card" ? (
                  <>
                    <p className="message__text message__text--light">
                      【クレジットカードの方】
                    </p>
                    <p className="message__text message__text--light">
                      ご登録頂いたメールアドレスに入会完了のメールを送付いたしましたので、ご確認ください。
                    </p>
                    <p className="message__text message__text--light">
                      携帯やスマートフォンアドレスをご記入された場合や、迷惑メールの対策などでドメイン指定を行なっている場合はメールが受信できない場合がございます。
                      ドメイン指定受信・メール指定受信にて「@worldplus-gym.com」からメールを受信できるよう設定してください。
                    </p>
                    <p className="message__text message__text--light">
                      入会完了メールが受信できない場合は恐れ入りますが、
                      <a href="{/*= ENV['WEB_MAIN_URL'] */}/contact/">こちら</a>
                      にお問い合わせください。
                    </p>
                    {input.shopType !== "directly" && (
                      <>
                        <p className="message__text message__text--light">
                          選択した入会日以降のスタッフ在中時間に身分証を持参のうえ、店舗へお越しいただき、施設利用の為の指紋と会員カード登録をお願いします。
                        </p>
                        <p className="message__text message__text--light">
                          ※スタッフ不在の曜日・時間もございますので、お電話等でご確認ください。
                        </p>
                        <p className="message__text message__text--light">
                          入会用会員IDはお問い合わせの際に必要になるので、画面を保存するかメモをお取りください。
                          ご不明な点がある場合は、入会用会員IDとお名前を明記の上、
                          <a href="{/*= ENV['WEB_MAIN_URL'] */}/contact/">
                            こちら
                          </a>
                          にお問い合わせください。
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="message__text message__text--light">
                      【口座振替の方】
                    </p>
                    <p className="message__text message__text--light">
                      決済登録が完了いたしました。
                      ご登録いただいたメールアドレスに申込み結果通知メールが送付されますので、ご確認ください。
                      遅くとも翌日10:00までには送信されます。
                    </p>
                    <p className="message__text message__text--light">
                      携帯やスマートフォンアドレスをご記入された場合や、迷惑メールの対策などでドメイン指定を行なっている場合はメールが受信できない場合がございます。
                      ドメイン指定受信・メール指定受信にて「@worldplus-gym.com」からメールを受信できるよう設定してください。
                    </p>
                    <p className="message__text message__text--light">
                      申込み結果通知メールが受信できない場合は、恐れ入りますが{" "}
                      <a href="{/*= ENV['WEB_MAIN_URL'] */}/contact/">こちら</a>{" "}
                      にお問い合わせください。
                    </p>
                  </>
                )}
              </div>
            </div>
            {/* /message */}

            <div className="content__more js-indicate js-indicate-btin">
              <a
                href="{/*= @shop_url */}"
                className="button button--large button--arrow"
              >
                <span className="button__label">
                  {/*= @shop_name */}　店舗ページへ
                </span>
              </a>
            </div>
            <img
              src="https://www.worldplus-gym.com/common/api/yad_end.php"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* /content */}
    </main>
    // /main
  );
};

export default RegisterComplete;
